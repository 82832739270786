<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-userCustomerLog">
          <div class="row">
            <div class="col-12 page-header">
              <img :src="require('@/assets/images/ico-import.svg')" alt="" />
              {{ lbl['user-customer-log-title'] }}
            </div>
          </div>

          <div class="text-center my-3" ng-controller="ImportUserCtrl">
            <div class="btn-group" role="group" aria-label="Button group">
              <a-button-group>
                <a-button @click="goto('UserCustomerManage')">
                  {{ lbl['manage-usr-edit-profile'] }}
                </a-button>
                <a-button type="primary">
                  {{ lbl['manage-usr-edit-log'] }}
                </a-button>
              </a-button-group>
            </div>
            <div class="mt-5 p-3 search-box">
              <a-form :form="form" class="search-form">
                <div class="row pt-5 pb-2">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <label class="text-left w-100">{{
                      lbl['import-search-userid']
                    }}</label>
                    <input
                      v-model="filters.user_id"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                    <div class="form-button text-left">
                      <a-button
                        type="primary"
                        class="width-120 mt-1"
                        @click="handleSearch()"
                      >
                        {{ lbl['import-search-button'] }}
                      </a-button>
                    </div>
                  </div>
                </div>
              </a-form>
            </div>
            <div class="pt-3 table-box">
              <a-table
                row-key="RowKey"
                :columns="columnsDetail"
                :data-source="dataDetail"
                :row-class-name="
                  (record, rowindex) => {
                    if (rowindex == dataDetail.length - 1) {
                      return 'tableHighlightlog'
                    }
                  }
                "
                :pagination="{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                  pageSizeOptions: pageSizeOptions,
                  pageSize: defaultPageSize,
                  defaultCurrent: 1,
                  showSizeChanger: true,
                  hideOnSinglePage: false,
                  onChange: onChangePageDetail,
                }"
              >
                <template slot="status" slot-scope="text, record">
                  <span v-if="record.Status == 'Active'">
                    {{ lbl['active'] }}</span
                  >
                  <span v-else-if="record.Status == 'Inactive'">
                    {{ lbl['inactive'] }}</span
                  >
                  <span v-else-if="record.Status == 'Cancel Member'">
                    {{ lbl['cancel-member'] }}</span
                  >
                  <span v-else-if="record.Status == 'Inactive by BOF'">
                    {{ lbl['inactive-by-bof'] }}</span
                  >
                  <span v-else> {{ text ? text : '-' }}</span>
                </template>
                <template slot="sex" slot-scope="text, record">
                  <span v-if="record.Gender == 'male'">{{ lbl['Male'] }}</span>
                  <span v-else-if="record.Gender == 'female'">{{
                    lbl['Female']
                  }}</span>
                  <span v-else>{{ lbl['Other'] }}</span>
                </template>
                <template slot="date" slot-scope="text">
                  {{ text ? dateFormat(text) : '-' }}
                </template>
                <template slot="date-time" slot-scope="text">
                  {{ text ? datetimeFormat(text) : '-' }}
                </template>
                <template slot="isdata" slot-scope="text">
                  {{ text ? text : '-' }}
                </template>
                <template slot="isdata_level" slot-scope="text, record">
                  <span
                    v-if="
                      record.LevelName != null && record.LevelName != 'null'
                    "
                    >{{ text ? text : '-' }}
                  </span>
                  <span v-else>
                    {{ '-' }}
                  </span>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import BzbsMamageUser from '@/core/Import/service/BzbsMamageUser'
import Account from '@/helper/AccountHelper.js'
import Header from '@/components/Layout/Header'
import moment from 'moment'
import Locale from '@/helper/locale.js'
import Vue from 'vue'

const columnsDetail = [
  {
    title: 'First Name',
    dataIndex: 'FirstName',
    key: 'manage-usr-first-name',
    width: '12%',
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'LastName',
    dataIndex: 'LastName',
    key: 'manage-usr-last-name',
    width: '12%',
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'MobileNumber',
    dataIndex: 'ContactNumber',
    key: 'manage-usr-contact-number',
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'Email',
    dataIndex: 'Email',
    key: 'manage-usr-email',
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'Point',
    dataIndex: 'Point',
    key: 'import-search-point',
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'import-search-status',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: 'Reference',
    dataIndex: 'ReferenceInfo',
    key: 'manage-usr-reference-code',
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'Reference2',
    dataIndex: 'ReferenceInfo2',
    key: 'manage-usr-reference-number2',
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'Gender',
    dataIndex: 'Gender',
    key: 'manage-usr-gender',
    scopedSlots: {
      customRender: 'sex',
    },
  },
  {
    title: 'BirthDate',
    dataIndex: 'BirthDate',
    key: 'manage-usr-birth-date',
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: 'UserLevel',
    dataIndex: 'LevelName',
    key: 'import-search-userlevel',
    scopedSlots: {
      customRender: 'isdata_level',
    },
  },
  {
    title: 'RegisterDate',
    dataIndex: 'RegisterDate',
    key: 'register-date-header',
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: 'TimeStamp',
    dataIndex: 'Timestamp',
    key: 'manage-usr-time-stamped',
    scopedSlots: {
      customRender: 'date-time',
    },
  },
  {
    title: 'TermAndCondition',
    dataIndex: 'TermAndCondition',
    key: 'dashboard-insight-user-info-term-and-condition',
    width: 180,
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'DataPrivacy',
    dataIndex: 'DataPrivacy',
    key: 'dashboard-insight-user-info-privacy-policy',
    width: 180,
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'LineMarketing',
    dataIndex: 'LineMarketing',
    key: 'dashboard-insight-user-info-line-marketing',
    width: 150,
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'SMSMarketing',
    dataIndex: 'SMSMarketing',
    key: 'dashboard-insight-user-info-sms-marketing',
    width: 150,
    scopedSlots: {
      customRender: 'isdata',
    },
  },
  {
    title: 'EmailMarketing',
    dataIndex: 'EmailMarketing',
    key: 'dashboard-insight-user-info-email-marketing',
    width: 150,
    scopedSlots: {
      customRender: 'isdata',
    },
  },
]

export default {
  name: 'userCustomerLog',
  mixins: [Mixin],
  components: {
    Header,
  },
  data: function () {
    return {
      data: [],
      total: null,
      columnsDetail,
      dataDetail: [],
      totalDetail: null,
      currentDetail: 1,
      filters: {
        user_id: '',
      },
      storetype: '',
      isBeeBanefit: false,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'import-user-search',
      onFieldsChange: prop => {
        if (prop.isFieldsTouched()) {
          this.formTouch = true
        }
      },
    })
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'member_details_log_page',
      'member_details_log',
      'view_member_log',
      'on view',
    )
    var crmPackage = Account.getCachePackage()
    if (crmPackage && crmPackage.PackageId) {
      this.isBeeBanefit = crmPackage.PackageId.includes('benefit')
    }
    if (this.isBeeBanefit) {
      this.columnsDetail = [
        {
          title: 'First Name',
          dataIndex: 'FirstName',
          key: 'manage-usr-first-name',
          width: '12%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'LastName',
          dataIndex: 'LastName',
          key: 'manage-usr-last-name',
          width: '12%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'MobileNumber',
          dataIndex: 'ContactNumber',
          key: 'manage-usr-contact-number',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'manage-usr-email',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Point',
          dataIndex: 'Point',
          key: 'import-search-point',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          key: 'import-search-status',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: 'Gender',
          dataIndex: 'Gender',
          key: 'manage-usr-gender',
          scopedSlots: {
            customRender: 'sex',
          },
        },
        {
          title: 'BirthDate',
          dataIndex: 'BirthDate',
          key: 'manage-usr-birth-date',
          scopedSlots: {
            customRender: 'date',
          },
        },
        {
          title: 'RegisterDate',
          dataIndex: 'RegisterDate',
          key: 'register-date-header',
          scopedSlots: {
            customRender: 'date',
          },
        },
        {
          title: 'TimeStamp',
          dataIndex: 'Timestamp',
          key: 'manage-usr-time-stamped',
          scopedSlots: {
            customRender: 'date-time',
          },
        },
      ]
    }
    if (Vue.bzbsConfig.businessRule.webFor.PH) {
      this.columnsDetail = _.forEach(this.columnsDetail, (column, index) => {
        if (column) {
          if (column.dataIndex) {
            if (column.dataIndex == 'LineMarketing') {
              this.columnsDetail.splice(index, 1)
            }
          }
        }
      })
      this.setLocale()
    } else {
      this.setLocale()
    }

    this.storetype = Account.getStoreType()
    console.log('storetype : ', this.storetype)
  },
  methods: {
    setLocale() {
      _.forEach(this.columnsDetail, column => {
        column.title = this.lbl[column.key]
      })
    },
    onChangePageDetail(page) {
      this.currentDetail = page
      this.getSearchCustomerLog((page - 1) * 10)
    },
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    handleSearch() {
      console.log('columnsDetail : ', this.columnsDetail)
      Account.bzbsAnalyticTracking(
        'member_details_log_page',
        'member_details_log',
        'click_member_log_search',
        'on view',
      )
      _.forEach(this.columnsDetail, column => {
        if (column.dataIndex == 'Point' && this.storetype == 'stamp') {
          column.className = 'd-none'
        }
      })

      this.currentDetail = 1
      this.getSearchCustomerLog((this.currentDetail - 1) * 10)
    },
    getSearchCustomerLog(skip) {
      this.handleLoading(true)

      return new Promise(resolve => {
        BzbsMamageUser.getSearchUserCustomerLog(this.filters.user_id)
          .then(res => {
            this.handleLoading(false)
            console.log('res : ', res)
            this.totalDetail = res.data.length
            const data_skip = res.data.slice(skip)
            this.dataDetail = data_skip.slice(0, 10)

            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getSearchUserCustomerLog error', error)
            resolve(error)
          })
      })
    },
    dateFormat: function (timestamp) {
      moment.locale(Locale.getLocaleCode())
      var strDate = moment.unix(timestamp).format('DD MMM ')
      var year = moment.unix(timestamp).format('YYYY')
      year = this.getYearByLocale(year)

      return strDate + year
    },
    datetimeFormat: function (timestamp) {
      moment.locale(Locale.getLocaleCode())
      var strDate = moment.unix(timestamp).format('DD MMM ')
      var year = moment.unix(timestamp).format('YYYY')
      year = this.getYearByLocale(year)
      var time = moment.unix(timestamp).format('HH:mm:ss')

      return strDate + year + ' ' + time
    },
    getYearByLocale: function (year) {
      if (Locale.getLocaleCode() == 'en') {
        return year
      } else {
        return parseInt(year) + 543
      }
    },
    goto(page) {
      Account.bzbsAnalyticTracking(
        'member_details_page',
        'member_details_log',
        'click_member_details_page_tab',
        'on click',
      )
      this.$router.push({ name: page })
    },
  },
}
</script>

<style lang="scss">
.wrap-userCustomerLog {
  .ant-table-body {
    overflow-x: auto;
    white-space: nowrap;
  }
}
.tableHighlightlog {
  background-color: #eee;
}
</style>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.wrap-userCustomerLog {
  .form-control {
    height: 40px;
    font-size: 16px;
  }

  .search-box {
    border-left: 1px solid #aaa;
    border-top: 2px solid black;
    border-right: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    border-radius: 0.5em;
  }

  .text-left {
    text-align: left;
  }

  .width-120 {
    width: 120px;
  }

  .ico-excel {
    background-color: #217346;
    height: 1.5vw;
    padding: 0.25vw;
  }

  .button-import {
    height: 45px;
    padding: 5px;
    width: 130px;
    background-color: #ebecf0;
    border-color: #ebecf0;
    color: #000;
  }

  .button-import:hover {
    background-color: #e5e5e5;
    border-color: #e5e5e5;
  }

  .div-import {
    float: right;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    font-size: 10px;
    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: #fff7e6;
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    .box-pagination {
      display: inline-grid;
      width: 100%;
      text-align: end;
      margin: 0 0 20px 0;
    }
  }
}
</style>
